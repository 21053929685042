import { TFunction } from 'i18next'
import { CountryIconId } from 'driverama-core/components/countryIcon/CountryIcon'
import { useRouter } from 'next/router'

export type Languages = 'en' | 'de' | 'nl'
export type Region = 'de' | 'nl'

type LangObj = {
  lang: string
  country: string
  icon: CountryIconId
  value: Languages
}

const defaultLocale = (t: TFunction): LangObj => ({
  lang: t('core:header_menu_language_de'),
  country: t('core:header_menu_language_de_country'),
  icon: 'de',
  value: 'de'
})

export function languages(t: TFunction): LangObj[] {
  if (process.env.NEXT_PUBLIC_REGION === 'de') {
    return [
      {
        lang: t('core:header_menu_language_en'),
        country: t('core:header_menu_language_en_country'),
        icon: 'us',
        value: 'en'
      },
      defaultLocale(t)
    ]
  }

  // if (process.env.NEXT_PUBLIC_REGION === 'nl') {
  //   return [
  //     {
  //       lang: t('core:header_menu_language_en'),
  //       country: t('core:header_menu_language_en_country'),
  //       icon: 'us',
  //       value: 'en'
  //     },
  //     {
  //       lang: t('core:header_menu_language_nl'),
  //       country: t('core:header_menu_language_nl_country'),
  //       icon: 'nl',
  //       value: 'nl'
  //     }
  //   ]
  // }

  return [
    {
      lang: t('core:header_menu_language_en'),
      country: t('core:header_menu_language_en_country'),
      icon: 'us',
      value: 'en'
    }
  ]
}

export const currencies = (t: TFunction) =>
  [
    {
      name: t('core:header_menu_currency_czk_name'),
      code: t('core:header_menu_currency_czk_code'),
      icon: 'cz',
      value: 'czk'
    },
    {
      name: t('core:header_menu_currency_eur_name'),
      code: t('core:header_menu_currency_eur_code'),
      icon: 'eu',
      value: 'eur'
    }
  ] as const

export const regions = (t: TFunction) =>
  [
    {
      name: t('core:header_menu_region_de_name'),
      value: 'de',
      icon: 'de',
      url: process.env.NEXT_PUBLIC_BASE_URL_DE as string
    }
    // {
    //   name: t('core:header_menu_region_nl_name'),
    //   value: 'nl',
    //   icon: 'nl',
    //   url: process.env.NEXT_PUBLIC_BASE_URL_NL as string
    // }
  ] as const

export function useLocalisation<T extends TFunction>(t: T) {
  const { locale } = useRouter()

  const locales = languages(t)
  const allRegions = regions(t)
  const allCurrencies = currencies(t)
  const activeLocale = locales.find(l => l.value === locale) ?? defaultLocale(t)

  return {
    activeLocale,
    locales,
    regions: allRegions,
    currencies: allCurrencies
  }
}
