import dynamic from 'next/dynamic'

const AD = dynamic(() => import('driverama-core/images/icons/flags/AD.svg'))
const AE = dynamic(() => import('driverama-core/images/icons/flags/AE.svg'))
const AF = dynamic(() => import('driverama-core/images/icons/flags/AF.svg'))
const AG = dynamic(() => import('driverama-core/images/icons/flags/AG.svg'))
const AI = dynamic(() => import('driverama-core/images/icons/flags/AI.svg'))
const AL = dynamic(() => import('driverama-core/images/icons/flags/AL.svg'))
const AM = dynamic(() => import('driverama-core/images/icons/flags/AM.svg'))
const AO = dynamic(() => import('driverama-core/images/icons/flags/AO.svg'))
const AR = dynamic(() => import('driverama-core/images/icons/flags/AR.svg'))
const AS = dynamic(() => import('driverama-core/images/icons/flags/AS.svg'))
const AT = dynamic(() => import('driverama-core/images/icons/flags/AT.svg'))
const AU = dynamic(() => import('driverama-core/images/icons/flags/AU.svg'))
const AW = dynamic(() => import('driverama-core/images/icons/flags/AW.svg'))
const AZ = dynamic(() => import('driverama-core/images/icons/flags/AZ.svg'))
const BA = dynamic(() => import('driverama-core/images/icons/flags/BA.svg'))
const BB = dynamic(() => import('driverama-core/images/icons/flags/BB.svg'))
const BD = dynamic(() => import('driverama-core/images/icons/flags/BD.svg'))
const BE = dynamic(() => import('driverama-core/images/icons/flags/BE.svg'))
const BF = dynamic(() => import('driverama-core/images/icons/flags/BF.svg'))
const BG = dynamic(() => import('driverama-core/images/icons/flags/BG.svg'))
const BH = dynamic(() => import('driverama-core/images/icons/flags/BH.svg'))
const BI = dynamic(() => import('driverama-core/images/icons/flags/BI.svg'))
const BJ = dynamic(() => import('driverama-core/images/icons/flags/BJ.svg'))
const BM = dynamic(() => import('driverama-core/images/icons/flags/BM.svg'))
const BO = dynamic(() => import('driverama-core/images/icons/flags/BO.svg'))
const BR = dynamic(() => import('driverama-core/images/icons/flags/BR.svg'))
const BS = dynamic(() => import('driverama-core/images/icons/flags/BS.svg'))
const BT = dynamic(() => import('driverama-core/images/icons/flags/BT.svg'))
const BW = dynamic(() => import('driverama-core/images/icons/flags/BW.svg'))
const BY = dynamic(() => import('driverama-core/images/icons/flags/BY.svg'))
const BZ = dynamic(() => import('driverama-core/images/icons/flags/BZ.svg'))
const CA = dynamic(() => import('driverama-core/images/icons/flags/CA.svg'))
const CF = dynamic(() => import('driverama-core/images/icons/flags/CF.svg'))
const CH = dynamic(() => import('driverama-core/images/icons/flags/CH.svg'))
const CK = dynamic(() => import('driverama-core/images/icons/flags/CK.svg'))
const CL = dynamic(() => import('driverama-core/images/icons/flags/CL.svg'))
const CM = dynamic(() => import('driverama-core/images/icons/flags/CM.svg'))
const CN = dynamic(() => import('driverama-core/images/icons/flags/CN.svg'))
const CO = dynamic(() => import('driverama-core/images/icons/flags/CO.svg'))
const CR = dynamic(() => import('driverama-core/images/icons/flags/CR.svg'))
const CU = dynamic(() => import('driverama-core/images/icons/flags/CU.svg'))
const CX = dynamic(() => import('driverama-core/images/icons/flags/CX.svg'))
const CY = dynamic(() => import('driverama-core/images/icons/flags/CY.svg'))
const DE = dynamic(() => import('driverama-core/images/icons/flags/DE.svg'))
const DJ = dynamic(() => import('driverama-core/images/icons/flags/DJ.svg'))
const DK = dynamic(() => import('driverama-core/images/icons/flags/DK.svg'))
const DM = dynamic(() => import('driverama-core/images/icons/flags/DM.svg'))
const DO = dynamic(() => import('driverama-core/images/icons/flags/DO.svg'))
const DZ = dynamic(() => import('driverama-core/images/icons/flags/DZ.svg'))
const EC = dynamic(() => import('driverama-core/images/icons/flags/EC.svg'))
const EE = dynamic(() => import('driverama-core/images/icons/flags/EE.svg'))
const EG = dynamic(() => import('driverama-core/images/icons/flags/EG.svg'))
const EH = dynamic(() => import('driverama-core/images/icons/flags/EH.svg'))
const ER = dynamic(() => import('driverama-core/images/icons/flags/ER.svg'))
const ES = dynamic(() => import('driverama-core/images/icons/flags/ES.svg'))
const ET = dynamic(() => import('driverama-core/images/icons/flags/ET.svg'))
const FI = dynamic(() => import('driverama-core/images/icons/flags/FI.svg'))
const FJ = dynamic(() => import('driverama-core/images/icons/flags/FJ.svg'))
const FK = dynamic(() => import('driverama-core/images/icons/flags/FK.svg'))
const FM = dynamic(() => import('driverama-core/images/icons/flags/FM.svg'))
const FO = dynamic(() => import('driverama-core/images/icons/flags/FO.svg'))
const FR = dynamic(() => import('driverama-core/images/icons/flags/FR.svg'))
const GA = dynamic(() => import('driverama-core/images/icons/flags/GA.svg'))
const GD = dynamic(() => import('driverama-core/images/icons/flags/GD.svg'))
const GE = dynamic(() => import('driverama-core/images/icons/flags/GE.svg'))
const GG = dynamic(() => import('driverama-core/images/icons/flags/GG.svg'))
const GH = dynamic(() => import('driverama-core/images/icons/flags/GH.svg'))
const GI = dynamic(() => import('driverama-core/images/icons/flags/GI.svg'))
const GL = dynamic(() => import('driverama-core/images/icons/flags/GL.svg'))
const GM = dynamic(() => import('driverama-core/images/icons/flags/GM.svg'))
const GN = dynamic(() => import('driverama-core/images/icons/flags/GN.svg'))
const GQ = dynamic(() => import('driverama-core/images/icons/flags/GQ.svg'))
const GR = dynamic(() => import('driverama-core/images/icons/flags/GR.svg'))
const GT = dynamic(() => import('driverama-core/images/icons/flags/GT.svg'))
const GU = dynamic(() => import('driverama-core/images/icons/flags/GU.svg'))
const GW = dynamic(() => import('driverama-core/images/icons/flags/GW.svg'))
const GY = dynamic(() => import('driverama-core/images/icons/flags/GY.svg'))
const HK = dynamic(() => import('driverama-core/images/icons/flags/HK.svg'))
const HN = dynamic(() => import('driverama-core/images/icons/flags/HN.svg'))
const HR = dynamic(() => import('driverama-core/images/icons/flags/HR.svg'))
const HT = dynamic(() => import('driverama-core/images/icons/flags/HT.svg'))
const HU = dynamic(() => import('driverama-core/images/icons/flags/HU.svg'))
const ID = dynamic(() => import('driverama-core/images/icons/flags/ID.svg'))
const IE = dynamic(() => import('driverama-core/images/icons/flags/IE.svg'))
const IL = dynamic(() => import('driverama-core/images/icons/flags/IL.svg'))
const IM = dynamic(() => import('driverama-core/images/icons/flags/IM.svg'))
const IN = dynamic(() => import('driverama-core/images/icons/flags/IN.svg'))
const IO = dynamic(() => import('driverama-core/images/icons/flags/IO.svg'))
const IQ = dynamic(() => import('driverama-core/images/icons/flags/IQ.svg'))
const IR = dynamic(() => import('driverama-core/images/icons/flags/IR.svg'))
const IS = dynamic(() => import('driverama-core/images/icons/flags/IS.svg'))
const IT = dynamic(() => import('driverama-core/images/icons/flags/IT.svg'))
const JE = dynamic(() => import('driverama-core/images/icons/flags/JE.svg'))
const JM = dynamic(() => import('driverama-core/images/icons/flags/JM.svg'))
const JO = dynamic(() => import('driverama-core/images/icons/flags/JO.svg'))
const JP = dynamic(() => import('driverama-core/images/icons/flags/JP.svg'))
const KE = dynamic(() => import('driverama-core/images/icons/flags/KE.svg'))
const KG = dynamic(() => import('driverama-core/images/icons/flags/KG.svg'))
const KH = dynamic(() => import('driverama-core/images/icons/flags/KH.svg'))
const KI = dynamic(() => import('driverama-core/images/icons/flags/KI.svg'))
const KM = dynamic(() => import('driverama-core/images/icons/flags/KM.svg'))
const KN = dynamic(() => import('driverama-core/images/icons/flags/KN.svg'))
const KW = dynamic(() => import('driverama-core/images/icons/flags/KW.svg'))
const KY = dynamic(() => import('driverama-core/images/icons/flags/KY.svg'))
const KZ = dynamic(() => import('driverama-core/images/icons/flags/KZ.svg'))
const LB = dynamic(() => import('driverama-core/images/icons/flags/LB.svg'))
const LI = dynamic(() => import('driverama-core/images/icons/flags/LI.svg'))
const LK = dynamic(() => import('driverama-core/images/icons/flags/LK.svg'))
const LR = dynamic(() => import('driverama-core/images/icons/flags/LR.svg'))
const LS = dynamic(() => import('driverama-core/images/icons/flags/LS.svg'))
const LT = dynamic(() => import('driverama-core/images/icons/flags/LT.svg'))
const LU = dynamic(() => import('driverama-core/images/icons/flags/LU.svg'))
const LV = dynamic(() => import('driverama-core/images/icons/flags/LV.svg'))
const LY = dynamic(() => import('driverama-core/images/icons/flags/LY.svg'))
const MA = dynamic(() => import('driverama-core/images/icons/flags/MA.svg'))
const MC = dynamic(() => import('driverama-core/images/icons/flags/MC.svg'))
const MD = dynamic(() => import('driverama-core/images/icons/flags/MD.svg'))
const ME = dynamic(() => import('driverama-core/images/icons/flags/ME.svg'))
const MG = dynamic(() => import('driverama-core/images/icons/flags/MG.svg'))
const ML = dynamic(() => import('driverama-core/images/icons/flags/ML.svg'))
const MM = dynamic(() => import('driverama-core/images/icons/flags/MM.svg'))
const MN = dynamic(() => import('driverama-core/images/icons/flags/MN.svg'))
const MO = dynamic(() => import('driverama-core/images/icons/flags/MO.svg'))
const MQ = dynamic(() => import('driverama-core/images/icons/flags/MQ.svg'))
const MR = dynamic(() => import('driverama-core/images/icons/flags/MR.svg'))
const MS = dynamic(() => import('driverama-core/images/icons/flags/MS.svg'))
const MT = dynamic(() => import('driverama-core/images/icons/flags/MT.svg'))
const MU = dynamic(() => import('driverama-core/images/icons/flags/MU.svg'))
const MV = dynamic(() => import('driverama-core/images/icons/flags/MV.svg'))
const MW = dynamic(() => import('driverama-core/images/icons/flags/MW.svg'))
const MX = dynamic(() => import('driverama-core/images/icons/flags/MX.svg'))
const MY = dynamic(() => import('driverama-core/images/icons/flags/MY.svg'))
const MZ = dynamic(() => import('driverama-core/images/icons/flags/MZ.svg'))
const NA = dynamic(() => import('driverama-core/images/icons/flags/NA.svg'))
const NE = dynamic(() => import('driverama-core/images/icons/flags/NE.svg'))
const NF = dynamic(() => import('driverama-core/images/icons/flags/NF.svg'))
const NG = dynamic(() => import('driverama-core/images/icons/flags/NG.svg'))
const NI = dynamic(() => import('driverama-core/images/icons/flags/NI.svg'))
const NL = dynamic(() => import('driverama-core/images/icons/flags/NL.svg'))
const NO = dynamic(() => import('driverama-core/images/icons/flags/NO.svg'))
const NP = dynamic(() => import('driverama-core/images/icons/flags/NP.svg'))
const NR = dynamic(() => import('driverama-core/images/icons/flags/NR.svg'))
const NU = dynamic(() => import('driverama-core/images/icons/flags/NU.svg'))
const NZ = dynamic(() => import('driverama-core/images/icons/flags/NZ.svg'))
const OM = dynamic(() => import('driverama-core/images/icons/flags/OM.svg'))
const PA = dynamic(() => import('driverama-core/images/icons/flags/PA.svg'))
const PE = dynamic(() => import('driverama-core/images/icons/flags/PE.svg'))
const PF = dynamic(() => import('driverama-core/images/icons/flags/PF.svg'))
const PG = dynamic(() => import('driverama-core/images/icons/flags/PG.svg'))
const PH = dynamic(() => import('driverama-core/images/icons/flags/PH.svg'))
const PK = dynamic(() => import('driverama-core/images/icons/flags/PK.svg'))
const PR = dynamic(() => import('driverama-core/images/icons/flags/PR.svg'))
const PT = dynamic(() => import('driverama-core/images/icons/flags/PT.svg'))
const PW = dynamic(() => import('driverama-core/images/icons/flags/PW.svg'))
const PY = dynamic(() => import('driverama-core/images/icons/flags/PY.svg'))
const QA = dynamic(() => import('driverama-core/images/icons/flags/QA.svg'))
const RO = dynamic(() => import('driverama-core/images/icons/flags/RO.svg'))
const RS = dynamic(() => import('driverama-core/images/icons/flags/RS.svg'))
const RW = dynamic(() => import('driverama-core/images/icons/flags/RW.svg'))
const SA = dynamic(() => import('driverama-core/images/icons/flags/SA.svg'))
const SB = dynamic(() => import('driverama-core/images/icons/flags/SB.svg'))
const SC = dynamic(() => import('driverama-core/images/icons/flags/SC.svg'))
const SD = dynamic(() => import('driverama-core/images/icons/flags/SD.svg'))
const SE = dynamic(() => import('driverama-core/images/icons/flags/SE.svg'))
const SG = dynamic(() => import('driverama-core/images/icons/flags/SG.svg'))
const SI = dynamic(() => import('driverama-core/images/icons/flags/SI.svg'))
const SK = dynamic(() => import('driverama-core/images/icons/flags/SK.svg'))
const SL = dynamic(() => import('driverama-core/images/icons/flags/SL.svg'))
const SM = dynamic(() => import('driverama-core/images/icons/flags/SM.svg'))
const SN = dynamic(() => import('driverama-core/images/icons/flags/SN.svg'))
const SO = dynamic(() => import('driverama-core/images/icons/flags/SO.svg'))
const SR = dynamic(() => import('driverama-core/images/icons/flags/SR.svg'))
const SS = dynamic(() => import('driverama-core/images/icons/flags/SS.svg'))
const ST = dynamic(() => import('driverama-core/images/icons/flags/ST.svg'))
const SX = dynamic(() => import('driverama-core/images/icons/flags/SX.svg'))
const TD = dynamic(() => import('driverama-core/images/icons/flags/TD.svg'))
const TG = dynamic(() => import('driverama-core/images/icons/flags/TG.svg'))
const TH = dynamic(() => import('driverama-core/images/icons/flags/TH.svg'))
const TJ = dynamic(() => import('driverama-core/images/icons/flags/TJ.svg'))
const TK = dynamic(() => import('driverama-core/images/icons/flags/TK.svg'))
const TM = dynamic(() => import('driverama-core/images/icons/flags/TM.svg'))
const TN = dynamic(() => import('driverama-core/images/icons/flags/TN.svg'))
const TO = dynamic(() => import('driverama-core/images/icons/flags/TO.svg'))
const TR = dynamic(() => import('driverama-core/images/icons/flags/TR.svg'))
const TT = dynamic(() => import('driverama-core/images/icons/flags/TT.svg'))
const TV = dynamic(() => import('driverama-core/images/icons/flags/TV.svg'))
const TW = dynamic(() => import('driverama-core/images/icons/flags/TW.svg'))
const UA = dynamic(() => import('driverama-core/images/icons/flags/UA.svg'))
const UG = dynamic(() => import('driverama-core/images/icons/flags/UG.svg'))
const UY = dynamic(() => import('driverama-core/images/icons/flags/UY.svg'))
const VE = dynamic(() => import('driverama-core/images/icons/flags/VE.svg'))
const VG = dynamic(() => import('driverama-core/images/icons/flags/VG.svg'))
const VU = dynamic(() => import('driverama-core/images/icons/flags/VU.svg'))
const WS = dynamic(() => import('driverama-core/images/icons/flags/WS.svg'))
const YE = dynamic(() => import('driverama-core/images/icons/flags/YE.svg'))
const ZA = dynamic(() => import('driverama-core/images/icons/flags/ZA.svg'))
const ZM = dynamic(() => import('driverama-core/images/icons/flags/ZM.svg'))
const ZW = dynamic(() => import('driverama-core/images/icons/flags/ZW.svg'))
const AX = dynamic(() => import('driverama-core/images/icons/flags/AX.svg'))
const BQ = dynamic(() => import('driverama-core/images/icons/flags/BQ.svg'))
const BN = dynamic(() => import('driverama-core/images/icons/flags/BN.svg'))
const CV = dynamic(() => import('driverama-core/images/icons/flags/CV.svg'))
const CC = dynamic(() => import('driverama-core/images/icons/flags/CC.svg'))
const AC = dynamic(() => import('driverama-core/images/icons/flags/AC.svg'))
const CG = dynamic(() => import('driverama-core/images/icons/flags/CG.svg'))
const CD = dynamic(() => import('driverama-core/images/icons/flags/CD.svg'))
const CI = dynamic(() => import('driverama-core/images/icons/flags/CI.svg'))
const CZ = dynamic(() => import('driverama-core/images/icons/flags/CZ.svg'))
const CW = dynamic(() => import('driverama-core/images/icons/flags/CW.svg'))
const SV = dynamic(() => import('driverama-core/images/icons/flags/SV.svg'))
const GF = dynamic(() => import('driverama-core/images/icons/flags/GF.svg'))
const GP = dynamic(() => import('driverama-core/images/icons/flags/GP.svg'))
const VA = dynamic(() => import('driverama-core/images/icons/flags/VA.svg'))
const XK = dynamic(() => import('driverama-core/images/icons/flags/XK.svg'))
const LA = dynamic(() => import('driverama-core/images/icons/flags/LA.svg'))
const MH = dynamic(() => import('driverama-core/images/icons/flags/MH.svg'))
const YT = dynamic(() => import('driverama-core/images/icons/flags/YT.svg'))
const NC = dynamic(() => import('driverama-core/images/icons/flags/NC.svg'))
const KP = dynamic(() => import('driverama-core/images/icons/flags/KP.svg'))
const MK = dynamic(() => import('driverama-core/images/icons/flags/MK.svg'))
const MP = dynamic(() => import('driverama-core/images/icons/flags/MP.svg'))
const PS = dynamic(() => import('driverama-core/images/icons/flags/PS.svg'))
const PL = dynamic(() => import('driverama-core/images/icons/flags/PL.svg'))
const RE = dynamic(() => import('driverama-core/images/icons/flags/RE.svg'))
const RU = dynamic(() => import('driverama-core/images/icons/flags/RU.svg'))
const BL = dynamic(() => import('driverama-core/images/icons/flags/BL.svg'))
const SH = dynamic(() => import('driverama-core/images/icons/flags/SH.svg'))
const LC = dynamic(() => import('driverama-core/images/icons/flags/LC.svg'))
const MF = dynamic(() => import('driverama-core/images/icons/flags/MF.svg'))
const PM = dynamic(() => import('driverama-core/images/icons/flags/PM.svg'))
const VC = dynamic(() => import('driverama-core/images/icons/flags/VC.svg'))
const KR = dynamic(() => import('driverama-core/images/icons/flags/KR.svg'))
const SJ = dynamic(() => import('driverama-core/images/icons/flags/SJ.svg'))
const SZ = dynamic(() => import('driverama-core/images/icons/flags/SZ.svg'))
const SY = dynamic(() => import('driverama-core/images/icons/flags/SY.svg'))
const TZ = dynamic(() => import('driverama-core/images/icons/flags/TZ.svg'))
const TL = dynamic(() => import('driverama-core/images/icons/flags/TL.svg'))
const TA = dynamic(() => import('driverama-core/images/icons/flags/TA.svg'))
const TC = dynamic(() => import('driverama-core/images/icons/flags/TC.svg'))
const GB = dynamic(() => import('driverama-core/images/icons/flags/GB.svg'))
const US = dynamic(() => import('driverama-core/images/icons/flags/US.svg'))
const UZ = dynamic(() => import('driverama-core/images/icons/flags/UZ.svg'))
const VN = dynamic(() => import('driverama-core/images/icons/flags/VN.svg'))
const VI = dynamic(() => import('driverama-core/images/icons/flags/VI.svg'))
const WF = dynamic(() => import('driverama-core/images/icons/flags/WF.svg'))
const OTHER = dynamic(
  () => import('driverama-core/images/icons/flags/OTHER.svg')
)

export function Flag({ name, ...rest }: { name: string }) {
  switch (name) {
    case 'WF':
      return <WF {...rest} />
    case 'VI':
      return <VI {...rest} />
    case 'VN':
      return <VN {...rest} />
    case 'UZ':
      return <UZ {...rest} />
    case 'US':
      return <US {...rest} />
    case 'GB':
      return <GB {...rest} />
    case 'TC':
      return <TC {...rest} />
    case 'TA':
      return <TA {...rest} />
    case 'TL':
      return <TL {...rest} />
    case 'TZ':
      return <TZ {...rest} />
    case 'SY':
      return <SY {...rest} />
    case 'SZ':
      return <SZ {...rest} />
    case 'SJ':
      return <SJ {...rest} />
    case 'KR':
      return <KR {...rest} />
    case 'VC':
      return <VC {...rest} />
    case 'PM':
      return <PM {...rest} />
    case 'MF':
      return <MF {...rest} />
    case 'LC':
      return <LC {...rest} />
    case 'SH':
      return <SH {...rest} />
    case 'BL':
      return <BL {...rest} />
    case 'RU':
      return <RU {...rest} />
    case 'RE':
      return <RE {...rest} />
    case 'PL':
      return <PL {...rest} />
    case 'PS':
      return <PS {...rest} />
    case 'MP':
      return <MP {...rest} />
    case 'MK':
      return <MK {...rest} />
    case 'KP':
      return <KP {...rest} />
    case 'NC':
      return <NC {...rest} />
    case 'YT':
      return <YT {...rest} />
    case 'MH':
      return <MH {...rest} />
    case 'LA':
      return <LA {...rest} />
    case 'XK':
      return <XK {...rest} />
    case 'VA':
      return <VA {...rest} />
    case 'GP':
      return <GP {...rest} />
    case 'GF':
      return <GF {...rest} />
    case 'SV':
      return <SV {...rest} />
    case 'CW':
      return <CW {...rest} />
    case 'CZ':
      return <CZ {...rest} />
    case 'CI':
      return <CI {...rest} />
    case 'CD':
      return <CD {...rest} />
    case 'CG':
      return <CG {...rest} />
    case 'AC':
      return <AC {...rest} />
    case 'CC':
      return <CC {...rest} />
    case 'CV':
      return <CV {...rest} />
    case 'BN':
      return <BN {...rest} />
    case 'BQ':
      return <BQ {...rest} />
    case 'AX':
      return <AX {...rest} />
    case 'AD':
      return <AD {...rest} />
    case 'AE':
      return <AE {...rest} />
    case 'AF':
      return <AF {...rest} />
    case 'AG':
      return <AG {...rest} />
    case 'AI':
      return <AI {...rest} />
    case 'AL':
      return <AL {...rest} />
    case 'AM':
      return <AM {...rest} />
    case 'AO':
      return <AO {...rest} />
    case 'AR':
      return <AR {...rest} />
    case 'AS':
      return <AS {...rest} />
    case 'AT':
      return <AT {...rest} />
    case 'AU':
      return <AU {...rest} />
    case 'AW':
      return <AW {...rest} />
    case 'AZ':
      return <AZ {...rest} />
    case 'BA':
      return <BA {...rest} />
    case 'BB':
      return <BB {...rest} />
    case 'BD':
      return <BD {...rest} />
    case 'BE':
      return <BE {...rest} />
    case 'BF':
      return <BF {...rest} />
    case 'BG':
      return <BG {...rest} />
    case 'BH':
      return <BH {...rest} />
    case 'BI':
      return <BI {...rest} />
    case 'BJ':
      return <BJ {...rest} />
    case 'BM':
      return <BM {...rest} />
    case 'BO':
      return <BO {...rest} />
    case 'BR':
      return <BR {...rest} />
    case 'BS':
      return <BS {...rest} />
    case 'BT':
      return <BT {...rest} />
    case 'BW':
      return <BW {...rest} />
    case 'BY':
      return <BY {...rest} />
    case 'BZ':
      return <BZ {...rest} />
    case 'CA':
      return <CA {...rest} />
    case 'CF':
      return <CF {...rest} />
    case 'CH':
      return <CH {...rest} />
    case 'CK':
      return <CK {...rest} />
    case 'CL':
      return <CL {...rest} />
    case 'CM':
      return <CM {...rest} />
    case 'CN':
      return <CN {...rest} />
    case 'CO':
      return <CO {...rest} />
    case 'CR':
      return <CR {...rest} />
    case 'CU':
      return <CU {...rest} />
    case 'CX':
      return <CX {...rest} />
    case 'CY':
      return <CY {...rest} />
    case 'DE':
      return <DE {...rest} />
    case 'DJ':
      return <DJ {...rest} />
    case 'DK':
      return <DK {...rest} />
    case 'DM':
      return <DM {...rest} />
    case 'DO':
      return <DO {...rest} />
    case 'DZ':
      return <DZ {...rest} />
    case 'EC':
      return <EC {...rest} />
    case 'EE':
      return <EE {...rest} />
    case 'EG':
      return <EG {...rest} />
    case 'EH':
      return <EH {...rest} />
    case 'ER':
      return <ER {...rest} />
    case 'ES':
      return <ES {...rest} />
    case 'ET':
      return <ET {...rest} />
    case 'FI':
      return <FI {...rest} />
    case 'FJ':
      return <FJ {...rest} />
    case 'FK':
      return <FK {...rest} />
    case 'FM':
      return <FM {...rest} />
    case 'FO':
      return <FO {...rest} />
    case 'FR':
      return <FR {...rest} />
    case 'GA':
      return <GA {...rest} />
    case 'GD':
      return <GD {...rest} />
    case 'GE':
      return <GE {...rest} />
    case 'GG':
      return <GG {...rest} />
    case 'GH':
      return <GH {...rest} />
    case 'GI':
      return <GI {...rest} />
    case 'GL':
      return <GL {...rest} />
    case 'GM':
      return <GM {...rest} />
    case 'GN':
      return <GN {...rest} />
    case 'GQ':
      return <GQ {...rest} />
    case 'GR':
    case 'EL':
      return <GR {...rest} />
    case 'GT':
      return <GT {...rest} />
    case 'GU':
      return <GU {...rest} />
    case 'GW':
      return <GW {...rest} />
    case 'GY':
      return <GY {...rest} />
    case 'HK':
      return <HK {...rest} />
    case 'HN':
      return <HN {...rest} />
    case 'HR':
      return <HR {...rest} />
    case 'HT':
      return <HT {...rest} />
    case 'HU':
      return <HU {...rest} />
    case 'ID':
      return <ID {...rest} />
    case 'IE':
      return <IE {...rest} />
    case 'IL':
      return <IL {...rest} />
    case 'IM':
      return <IM {...rest} />
    case 'IN':
      return <IN {...rest} />
    case 'IO':
      return <IO {...rest} />
    case 'IQ':
      return <IQ {...rest} />
    case 'IR':
      return <IR {...rest} />
    case 'IS':
      return <IS {...rest} />
    case 'IT':
      return <IT {...rest} />
    case 'JE':
      return <JE {...rest} />
    case 'JM':
      return <JM {...rest} />
    case 'JO':
      return <JO {...rest} />
    case 'JP':
      return <JP {...rest} />
    case 'KE':
      return <KE {...rest} />
    case 'KG':
      return <KG {...rest} />
    case 'KH':
      return <KH {...rest} />
    case 'KI':
      return <KI {...rest} />
    case 'KM':
      return <KM {...rest} />
    case 'KN':
      return <KN {...rest} />
    case 'KW':
      return <KW {...rest} />
    case 'KY':
      return <KY {...rest} />
    case 'KZ':
      return <KZ {...rest} />
    case 'LB':
      return <LB {...rest} />
    case 'LI':
      return <LI {...rest} />
    case 'LK':
      return <LK {...rest} />
    case 'LR':
      return <LR {...rest} />
    case 'LS':
      return <LS {...rest} />
    case 'LT':
      return <LT {...rest} />
    case 'LU':
      return <LU {...rest} />
    case 'LV':
      return <LV {...rest} />
    case 'LY':
      return <LY {...rest} />
    case 'MA':
      return <MA {...rest} />
    case 'MC':
      return <MC {...rest} />
    case 'MD':
      return <MD {...rest} />
    case 'ME':
      return <ME {...rest} />
    case 'MG':
      return <MG {...rest} />
    case 'ML':
      return <ML {...rest} />
    case 'MM':
      return <MM {...rest} />
    case 'MN':
      return <MN {...rest} />
    case 'MO':
      return <MO {...rest} />
    case 'MQ':
      return <MQ {...rest} />
    case 'MR':
      return <MR {...rest} />
    case 'MS':
      return <MS {...rest} />
    case 'MT':
      return <MT {...rest} />
    case 'MU':
      return <MU {...rest} />
    case 'MV':
      return <MV {...rest} />
    case 'MW':
      return <MW {...rest} />
    case 'MX':
      return <MX {...rest} />
    case 'MY':
      return <MY {...rest} />
    case 'MZ':
      return <MZ {...rest} />
    case 'NA':
      return <NA {...rest} />
    case 'NE':
      return <NE {...rest} />
    case 'NF':
      return <NF {...rest} />
    case 'NG':
      return <NG {...rest} />
    case 'NI':
      return <NI {...rest} />
    case 'NL':
      return <NL {...rest} />
    case 'NO':
      return <NO {...rest} />
    case 'NP':
      return <NP {...rest} />
    case 'NR':
      return <NR {...rest} />
    case 'NU':
      return <NU {...rest} />
    case 'NZ':
      return <NZ {...rest} />
    case 'OM':
      return <OM {...rest} />
    case 'PA':
      return <PA {...rest} />
    case 'PE':
      return <PE {...rest} />
    case 'PF':
      return <PF {...rest} />
    case 'PG':
      return <PG {...rest} />
    case 'PH':
      return <PH {...rest} />
    case 'PK':
      return <PK {...rest} />
    case 'PR':
      return <PR {...rest} />
    case 'PT':
      return <PT {...rest} />
    case 'PW':
      return <PW {...rest} />
    case 'PY':
      return <PY {...rest} />
    case 'QA':
      return <QA {...rest} />
    case 'RO':
      return <RO {...rest} />
    case 'RS':
      return <RS {...rest} />
    case 'RW':
      return <RW {...rest} />
    case 'SA':
      return <SA {...rest} />
    case 'SB':
      return <SB {...rest} />
    case 'SC':
      return <SC {...rest} />
    case 'SD':
      return <SD {...rest} />
    case 'SE':
      return <SE {...rest} />
    case 'SG':
      return <SG {...rest} />
    case 'SI':
      return <SI {...rest} />
    case 'SK':
      return <SK {...rest} />
    case 'SL':
      return <SL {...rest} />
    case 'SM':
      return <SM {...rest} />
    case 'SN':
      return <SN {...rest} />
    case 'SO':
      return <SO {...rest} />
    case 'SR':
      return <SR {...rest} />
    case 'SS':
      return <SS {...rest} />
    case 'ST':
      return <ST {...rest} />
    case 'SX':
      return <SX {...rest} />
    case 'TD':
      return <TD {...rest} />
    case 'TG':
      return <TG {...rest} />
    case 'TH':
      return <TH {...rest} />
    case 'TJ':
      return <TJ {...rest} />
    case 'TK':
      return <TK {...rest} />
    case 'TM':
      return <TM {...rest} />
    case 'TN':
      return <TN {...rest} />
    case 'TO':
      return <TO {...rest} />
    case 'TR':
      return <TR {...rest} />
    case 'TT':
      return <TT {...rest} />
    case 'TV':
      return <TV {...rest} />
    case 'TW':
      return <TW {...rest} />
    case 'UA':
      return <UA {...rest} />
    case 'UG':
      return <UG {...rest} />
    case 'UY':
      return <UY {...rest} />
    case 'VE':
      return <VE {...rest} />
    case 'VG':
      return <VG {...rest} />
    case 'VU':
      return <VU {...rest} />
    case 'WS':
      return <WS {...rest} />
    case 'YE':
      return <YE {...rest} />
    case 'ZA':
      return <ZA {...rest} />
    case 'ZM':
      return <ZM {...rest} />
    case 'ZW':
      return <ZW {...rest} />
    default:
      return <OTHER {...rest} />
  }
}
