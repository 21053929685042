import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { operations } from 'driverama-core/api/driverama/generated/cars'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type PartnersSearchResponse = operations['search']['responses']['200']['content']['application/com.driverama-v1+json']
type PartnersSearchBody = operations['search']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, PartnersSearchResponse>

export function usePartnersSearchQuery(
  body: PartnersSearchBody,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.partnersSearch(body),
    async () => {
      const res = await apiAuthFetcher<PartnersSearchResponse>(
        URLS.partnersSearch,
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    opts
  )
}

export function usePartnersList() {
  return usePartnersSearchQuery(
    {
      erpIds: []
    },
    {
      refetchOnWindowFocus: false
    }
  )
}

export function usePartnerMapper() {
  const partners = usePartnersList()
  return (erpId?: string) => {
    if (process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'production') {
      switch (erpId) {
        case 'C1_000001':
          return 'Aures CZ'

        case 'C1_000002':
          return 'Aures SK'

        case 'C1_000006':
          return 'Driverama DE'

        case 'C1_000003':
          return 'Aures PL'

        default:
          return partners.data?.content.find(partner => partner.erpId === erpId)
            ?.name
      }
    }

    return partners.data?.content.find(partner => partner.erpId === erpId)?.name
  }
}
