import { useCallback, useEffect, useState } from 'react'

export function useNotificationPermission() {
  const [permission, setPermission] = useState<
    'granted' | 'denied' | 'default'
  >()

  const requestPermission = useCallback(async () => {
    const res = await Notification.requestPermission()
    setPermission(res)
  }, [setPermission])

  useEffect(() => {
    requestPermission()
  }, [requestPermission])

  return permission
}
