import styled from '@emotion/styled'
import { color, time } from 'driverama-core/styles/variables'
import { size } from 'driverama-core/styles/spacing'
import { TextBody } from 'driverama-core/components/text/Text'

export const SContent = styled(TextBody)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: ${size(2)};
`

export const SContainer = styled.div<{ variant?: 'dark' | 'light' }>`
  position: relative;

  width: 100%;
  height: 2px;

  background-color: ${({ variant }) =>
    color(variant === 'dark' ? 'night-l-650' : 'night-l-800')};
  transition: all ${time('control')} ease-in-out;

  ${SContent} {
    background-color: ${({ variant }) =>
      color(variant === 'dark' ? 'night-l-800' : 'white')};
  }
`
