import { AuctionListData } from 'sections/auctions/Auctions.utils'

export const sortItems = (
  a: AuctionListData,
  b: AuctionListData,
  order: 'asc' | 'desc' = 'desc'
): number => {
  const aStart = a.startedAt ? new Date(a.startedAt).getTime() : 0
  const bStart = b.startedAt ? new Date(b.startedAt).getTime() : 0
  const aCreated = a.createdAt ? new Date(a.createdAt).getTime() : 0
  const bCreated = b.createdAt ? new Date(b.createdAt).getTime() : 0

  if (a.startedAt && !b.startedAt) return order === 'desc' ? -1 : 1
  if (!a.startedAt && b.startedAt) return order === 'desc' ? 1 : -1
  if (a.startedAt && b.startedAt)
    return order === 'desc' ? bStart - aStart : aStart - bStart
  return order === 'desc' ? bCreated - aCreated : aCreated - bCreated
}
