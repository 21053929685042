import { Maybe } from 'driverama-core/utils/types'

export const URLS = {
  lovModelLinesSearch: '/lov/model-lines/search',
  lovExtendedModelsSearch: '/lov/extended-models/search',
  lovModelsSearch: '/lov/models/search',
  lovMakesSearch: '/lov/makes/search',
  lovEnginesSearch: '/lov/engines/search',
  lovCarFeaturesSearch: '/lov/car-feature-items/search',
  lovCarFeaturesSections: '/lov/car-feature-sections',
  lovCarFeaturesCategories: '/lov/car-feature-categories',
  lovBranchesSearch: '/lov/branches/search',
  lovTransmissions: '/lov/transmissions',
  lovFuelTypes: '/lov/fuel-types',
  lovDrives: '/lov/drives',
  lovMakes: '/lov/makes',
  lovCarTypes: '/lov/car-types',
  lovBodies: '/lov/bodies',
  lovBodiesSearch: '/lov/bodies/search',
  lovColors: '/lov/colors',
  lovCountries: '/lov/countries',
  lovPostCodes: '/lov/post-codes',
  lovEmissionNorms: '/lov/emission-norms',

  customersTokenRefresh: '/customers/tokens/refresh',
  customersRegister: '/customers/register',
  customersLogin: '/customers/login',
  customerSelf: '/customers/self',
  customerAgreements: '/customers/agreements',
  customerMarketingCheck: '/customers/marketing-check',
  customerMarketingCheckWithToken: '/customers/marketing-check-by-token',
  customerVerifyEmail: (token: string) => `/customers/verify-email/${token}`,

  employeesSearch: '/employees/search',
  employeeSelf: '/employees/self',

  opportunityInit: '/opportunities/init',
  opportunityBasicInfo: '/opportunities/basic-info',
  opportunitiesSearch: '/opportunities/search',
  opportunityDetail: (opportunityId?: string) =>
    `/opportunities/${opportunityId}`,
  opportunityAssign: (opportunityId?: string) =>
    `/opportunities/${opportunityId}/assign`,
  opportunityCancel: (opportunityId?: string) =>
    `/opportunities/${opportunityId}/cancel`,
  opportunityEVRequestCall: '/opportunities/ev/request-call',
  opportunityPriceRangeRequestCall: '/opportunities/price-range/request-call',

  createOrUpdateAppointment: (opportunityId?: string) =>
    `/opportunities/${opportunityId}/appointment`,
  saveCancelReason: (opportunityId?: string) =>
    `/opportunities/${opportunityId}/appointment/cancel-reason`,

  freeSlotsBranch: (branchId?: string) =>
    `/opportunities/appointments/free-slots/for-branch/${branchId}`,

  carSaveCondition: (carId?: string) =>
    `/opportunities/cars/${carId}/condition`,
  carSaveFeatures: (carId?: string) => `/opportunities/cars/${carId}/features`,
  vinDecoderDecode: (vin?: string) => `/vindecoder/vin/${vin}/decode`,
  stockCarDetail: (carId?: string) => `/stock/cars/${carId}`,
  stockCarDetailAlt: (stockNo?: string) =>
    `/stock/cars/for-stock-no/${stockNo}`,
  carDetail: (stockNo?: string) =>
    `/stock/e-commerce/cars/for-stock-no/${stockNo}`,

  orderInit: `/orders/init`,
  orderDetail: (orderId?: string) => `/orders/${orderId}`,
  orderAssign: (orderId?: string) => `/orders/${orderId}/assign`,
  orderBasicsOwner: (orderId?: string) => `/orders/${orderId}/basics-owner`,
  orderBasicsCountry: (orderId?: string) => `/orders/${orderId}/basics-country`,
  orderDelivery: (orderId?: string) => `/orders/${orderId}/delivery`,
  orderDeliver: (orderId?: string) => `/orders/${orderId}/deliver`,
  orderSummary: (orderId?: string) => `/orders/${orderId}/summary`,
  orderOwnerPerson: (orderId?: string) => `/orders/${orderId}/owner/person`,
  orderOwnerCompany: (orderId?: string) => `/orders/${orderId}/owner/company`,
  orderOwnerSelfEmployed: (orderId?: string) =>
    `/orders/${orderId}/owner/self-employed`,
  orderCarRegistration: (orderId?: string) =>
    `/orders/${orderId}/car-registration`,
  orderCarRegistrationNoStepChange: (orderId?: string) =>
    `/orders/${orderId}/car-registration-no-step-change`,
  orderConfirmation: (orderId?: string) => `/orders/${orderId}/confirmation`,
  orderFeedback: (orderId?: string) => `/orders/${orderId}/feedback`,
  orderLoanCalculator: () => `/orders/loan-calculator`,
  orderDeliveryPrices: () => `/orders/delivery/price`,
  orderLoanRequest: `/orders/loan/request-call`,
  orderExtendedWarrantyRequest: `/orders/extended-warranty/request-call`,
  orderExtendedWarranty: (orderId: string) =>
    `/orders/extended-warranty/${orderId}`,
  ordersProfile: `/orders/customer-detail/my-orders`,
  orderPickupDeputy: (orderId?: string) => `/orders/${orderId}/pickup-deputy`,
  orderUpsales: `/orders/orderable-upsales`,
  orderHomeDelivery: (orderId?: string) => `/orders/${orderId}/home-delivery`,
  orderHomeDeliveryInfo: '/orders/home-delivery/info',
  orderHomeDeliveryAvailability: '/orders/home-delivery/availability',
  orderReservation: (orderId?: string, carId?: string) =>
    `/orders/${orderId}/car-reservation/${carId}`,
  orderNoteDetail: (noteId?: string) => `/orders/notes/${noteId}`,
  orderNotes: (orderId?: string) => `/orders/notes/for-order/${orderId}`,
  orderCarDetailRequestCall: '/orders/cars/detail/request-call',

  appointmentsFreeSlotsBranch: (branchId: string, type: string) =>
    `/appointments/free-slots/for-branch/${branchId}/${type}`,
  appointmentsFreeSlotsMobile: (branchId: string, type: string) =>
    `/appointments/free-slots/mobile/${branchId}/${type}`,

  contactUs: `/api/contact-us`,
  jobApplications: `/api/job-applications`,
  checkPhone: `/api/auth/phone/check`,
  checkEmail: `/api/auth/email/check`,
  resetEmailPassword: `/api/auth/email/reset`,
  extendedWarranties: (carId: Maybe<string>) =>
    `/orders/extended-warranty/eligibility/${carId}`,
  fuelTypeLabels: '/stock/e-commerce/fuel-type-labels',
  linearLoanCalculation: '/loan-calculator/linear-loan-calculation',
  balloonLoanCalculation: '/loan-calculator/balloon-loan-calculation'
}

export const DONT_KNOW_VALUE = 'DONT_KNOW_VALUE_FE_ONLY'
export const NOT_LISTED_VALUE = 'NOT_LISTED_VALUE_FE_ONLY'
