import { ReactNode } from 'react'
import { SContainer, SContent } from './Divider.styled'

interface Props {
  text?: ReactNode
  className?: string
  variant?: 'dark' | 'light'
}

export function Divider({ text, className, variant }: Props) {
  return (
    <SContainer className={className} variant={variant}>
      {text && <SContent variant="setup">{text}</SContent>}
    </SContainer>
  )
}
