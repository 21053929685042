import styled from '@emotion/styled'
import { color, radius, time } from 'driverama-core/styles/variables'
import { size } from 'driverama-core/styles/spacing'
import { css, keyframes } from '@emotion/react'
import { TextBody } from 'driverama-core/components/text/Text'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import IconCheck from 'driverama-core/images/icons/IconCheck.svg'
import IconPlus from 'driverama-core/images/icons/IconPlusLarge.svg'

const fadeIn = keyframes`
  from {
   opacity:0;
  }
  to {
   opacity: 1;
  }
`

export const SChip = styled(ButtonTransparent)<{
  checked: boolean
  hasIcon?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;

  border: 2px solid ${color('night-l-700')};
  border-radius: ${radius('full')};
  padding: ${size(1)} ${size(3)};
  transition: all ${time('control')} ease-in-out;

  ${TextBody} {
    white-space: nowrap;
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${color('night-l-700')};

      ${TextBody} {
        font-weight: bold;
        color: ${color('night-l-100')};
        margin-left: ${size(1)};
      }
    `};

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: ${size(7.5)};
    `};

  &:disabled {
    cursor: unset;
  }
`

export const SIconWrapper = styled.div<{
  checked?: boolean
  custom?: boolean
}>`
  position: absolute;
  top: 50%;
  left: ${size(2)};
  transform: translateY(-50%);

  animation: ${fadeIn} ${time('control')} ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${color('night-l-200')};
  border-radius: 100%;

  height: ${size(5)};
  width: ${size(5)};

  ${({ checked, custom }) =>
    (!checked || custom) &&
    css`
      background-color: transparent;
    `}
`

export const SIcon = styled(IconCheck)`
  color: ${color('white')};
`

export const SIconAdd = styled(IconPlus)`
  color: ${color('night-l-100')};
`
