import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovBranchesSearchResponse = operations['getBranches']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = operations['getBranches']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, LovBranchesSearchResponse>

export function useLovBranchesSearchQuery(
  body: QueryBody = {},
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovBranchesSearch(body),
    async () => {
      const res = await apiFetcher<LovBranchesSearchResponse>(
        URLS.lovBranchesSearch,
        { method: HTTPMethod.POST, body }
      )

      return res.json
    },
    { ...lovQueryOptions, ...opts }
  )
}

export function sortBranchesFn(
  x: LovBranchesSearchResponse['content'][0],
  y: LovBranchesSearchResponse['content'][0]
) {
  if (!x.name || !y.name) {
    return 0
  }

  return x.name.localeCompare(y.name)
}

function sortBranchesListFn(
  x: { label: string; value: string },
  y: { label: string; value: string }
) {
  if (!x.label || !y.label) {
    return 0
  }

  return x.label.localeCompare(y.label)
}

export type BranchListOptions = {
  onSuccess?: (data: LovBranchesSearchResponse) => void
  branchPickup?: boolean
  buying?: boolean
  homeDelivery?: boolean
  filters?: { active?: boolean; visible?: boolean }
}

export function useBranchList(options: BranchListOptions = {}) {
  const { onSuccess, branchPickup, buying, homeDelivery, filters } = options
  const { data, ...rest } = useLovBranchesSearchQuery(
    {
      filter: {
        ids: [],
        types: [],
        branchPickup,
        buying,
        homeDelivery,
        active: filters ? filters.active : true,
        visible: filters ? filters.visible : true
      }
    },
    { onSuccess, refetchOnWindowFocus: false }
  )

  const branches = useMemo(() => {
    return (
      data?.content.map(branch => ({
        label: branch.name || branch.id,
        value: branch.id,
        ...branch
      })) || []
    ).sort(sortBranchesListFn)
  }, [data])

  return { branches, ...rest }
}

export function useActiveBranchList(
  options?: Omit<BranchListOptions, 'filters'>
) {
  const { branches, ...rest } = useBranchList({
    ...options,
    filters: {
      active: true,
      visible: undefined
    }
  })

  return { branches, ...rest }
}

export function useBranchDetail(branchId: Maybe<string>) {
  const { data, ...rest } = useLovBranchesSearchQuery(
    { filter: { ids: branchId ? [branchId] : [], active: true, types: [] } },
    { enabled: !!branchId }
  )

  const branch = useMemo(() => {
    return data?.content.find(branch => branch.id === branchId)
  }, [branchId, data])

  return { ...rest, data, branch }
}

export type LovBranch = LovBranchesSearchResponse['content'][0]
export type LovBranchOpeningHours = LovBranch['openingHours']
