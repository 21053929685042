import { css } from '@emotion/react'
import { HeaderProfile } from 'components/header/profile/HeaderProfile'
import { LINKS } from 'constants/links'
import { Flex } from 'driverama-core/components/Flex'
import IconHistory from 'images/icons/IconHistory.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { SButton, SContainer, SLogo } from './Header.styled'

export function Header() {
  const router = useRouter()

  return (
    <SContainer>
      <Link href={LINKS.home}>
        <a>
          <SLogo />
        </a>
      </Link>
      <Flex
        variant="row"
        gap={4}
        css={css`
          @media print {
            display: none;
          }
        `}
      >
        <SButton variant="outline" onClick={() => router.push(LINKS.history)}>
          <IconHistory />
        </SButton>
        {/*<HeaderNotifications />*/}
        <HeaderProfile />
      </Flex>
    </SContainer>
  )
}
