import { LINKS } from 'constants/links'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { TextBody } from 'driverama-core/components/text/Text'
import IconLogout from 'images/icons/IconLogout.svg'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { SImage, SItem } from './HeaderProfile.styled'

export function HeaderProfile() {
  const { t } = useTranslation(['core'])
  const router = useRouter()

  async function handleLogout() {
    await router.push(LINKS.logout)
  }

  return (
    <Dropdown
      trigger={
        <ButtonTransparent>
          <SImage src="/placeholders/avatar.png" width={40} height={40} />
        </ButtonTransparent>
      }
    >
      <SItem onClick={handleLogout}>
        <TextBody>{t('core:header_profile_logout')}</TextBody>
        <IconLogout />
      </SItem>
    </Dropdown>
  )
}
