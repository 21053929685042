import { operations } from 'api/driverama/generated/cars'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

export type CarSearchBody = operations['searchCars']['requestBody']['content']['application/json']
export type CarSearchResponse =
  | operations['searchCars']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined
type QueryOpts = UseQueryOptions<unknown, unknown, CarSearchResponse>

export function useCarSearch(body: CarSearchBody, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.carSearch(body),
    async () => {
      const cars = await apiAuthFetcher<CarSearchResponse>(URLS.carSearch, {
        method: HTTPMethod.POST,
        body
      })
      return cars.json
    },
    opts
  )
}
