import { Primitive } from 'driverama-core/utils/types'

export function generateNumberArray(len: number) {
  return Array.from(Array(len).keys())
}

export type ObjectKey = string | number | symbol

export function groupBy<
  K extends ObjectKey,
  TItem extends Record<K, ObjectKey>
>(items: TItem[], key: K): Record<ObjectKey, TItem[]> {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {} as Record<ObjectKey, TItem[]>
  )
}

export function unique<TItem>(
  arr: TItem[],
  accesor?: (item: TItem) => Primitive
): TItem[] {
  if (!accesor) {
    return [...new Set(arr)]
  }

  const results: TItem[] = []
  const uniqueValues: Primitive[] = []

  for (const item of arr) {
    const uniqueValue = accesor(item)
    if (uniqueValues.includes(uniqueValue)) {
      continue
    }

    uniqueValues.push(uniqueValue)
    results.push(item)
  }

  return results
}

export function splitArrayToChunks<T>(arr: T[], chunkSize = 30): T[][] {
  return arr.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}
