import styled from '@emotion/styled'
import { ButtonCircle } from 'driverama-core/components/button/Button'
import Logo from 'driverama-core/images/Logo.svg'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, zIndex } from 'driverama-core/styles/variables'

export const SContainer = styled.header`
  z-index: calc(${zIndex('sticky')});

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${size(10)};

  background-color: ${color('white')};
  border-bottom: 2px solid ${color('night-l-700')};

  @media ${media.lte('tablet')} {
    padding: 0 ${size(5)};
    border-bottom: 0;
  }
`

export const SLogo = styled(Logo)`
  color: ${color('night-l-100')};
`

export const SButton = styled(ButtonCircle)`
  width: ${size(10)};
  height: ${size(10)};

  padding: 0;
`
