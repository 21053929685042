import styled from '@emotion/styled'
import { color } from 'driverama-core/styles/variables'
import { TextBodyBold } from 'driverama-core/components/text/Text'
import { components } from 'driverama-core/api/driverama/generated/auctions'

export const SContainer = styled.div<{
  result: components['schemas']['OpportunityResponse']['state']
}>`
  display: flex;
  align-items: center;

  color: ${({ result }) =>
    color(
      result === 'DISCARDED'
        ? 'alert'
        : result === 'LOST'
        ? 'warning'
        : result === 'DEAL' || result === 'APPROVED'
        ? 'night-l-100'
        : 'night-text'
    )};

  ${TextBodyBold} {
    color: inherit;
  }
`
