import { components } from 'driverama-core/api/driverama/generated/auctions'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextBodyBold } from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { SContainer } from './OpportunityResult.styled'

import { Flex } from 'driverama-core/components/Flex'
import { useOpportunityResult } from './OpportunityResult.utils'

interface Props {
  result: components['schemas']['OpportunityResponse']['state']
  lossReason?: components['schemas']['OpportunityResponse']['lossReason']
}

export function OpportunityResult({ result, lossReason }: Props) {
  const { t } = useTranslation(['core', 'history'])

  const { label, icon } = useOpportunityResult(result)

  return (
    <Flex variant="column">
      <SContainer result={result}>
        {icon}
        <Spacer axis="horizontal" size={1} />
        <TextBodyBold>{label}</TextBodyBold>
      </SContainer>
      {result === 'LOST' && lossReason && (
        <TextBody color="warning">{t(`history:status_${lossReason}`)}</TextBody>
      )}
    </Flex>
  )
}
