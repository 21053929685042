import { css } from '@emotion/react'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import ErrorTruck from 'driverama-core/images/ErrorTruck.svg'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

type Props = {
  onButtonClick?: () => void
  className?: string
  title: string
  message: string
  buttonLabel?: string
  small?: boolean
}

export function ErrorApi(props: Props) {
  const { onButtonClick, className, title, message, buttonLabel } = props

  return (
    <Flex
      variant="column"
      align="center"
      justify="center"
      className={className}
    >
      <ErrorTruck
        css={css`
          display: block;
          height: ${props.small ? size(24) : size(54)};

          @media ${media.lte('tablet')} {
            height: ${props.small ? size(17) : size(37)};
          }
        `}
      />

      <Spacer size={[6, 6, 9]} />

      <TextHeader
        as="h3"
        variant={props.small ? 'h5' : ['h5', 'h5', 'h3']}
        css={css`
          text-align: center;
        `}
      >
        {title}
      </TextHeader>

      <Spacer size={[2, 2, 4]} />

      <TextBody variant="body" size={['small', 'small', 'large']}>
        {message}
      </TextBody>

      {onButtonClick && (
        <>
          <Spacer size={[6, 6, 8]} />

          <Button
            variant="primary"
            onClick={onButtonClick}
            css={{ minWidth: size(55) }}
          >
            {buttonLabel}
          </Button>
        </>
      )}
    </Flex>
  )
}
