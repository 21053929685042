import { ReactNode, PropsWithChildren } from 'react'
import { SChip, SIconWrapper, SIcon, SIconAdd } from './Chip.styled'
import { TextBody } from 'driverama-core/components/text/Text'

interface Props {
  // TODO: discuss `checked` vs `active` naming
  checked?: boolean
  onClick?: () => void
  label?: string
  className?: string
  add?: boolean
  testId?: string
  icon?: ReactNode
  disabled?: boolean
}

export function Chip({
  checked = false,
  onClick,
  label,
  children,
  className,
  add,
  testId,
  icon,
  disabled
}: PropsWithChildren<Props>) {
  const hasIcon = !!(icon || add || checked)

  return (
    <SChip
      data-testid={testId}
      className={className}
      checked={checked}
      hasIcon={hasIcon}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <SIconWrapper custom>{icon}</SIconWrapper>}

      {!icon && add && !checked && (
        <SIconWrapper>
          <SIconAdd />
        </SIconWrapper>
      )}

      {!icon && checked && (
        <SIconWrapper checked={checked}>
          <SIcon />
        </SIconWrapper>
      )}
      <TextBody>{children || label}</TextBody>
    </SChip>
  )
}
