import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovEnginesSearchResponse = operations['getEngines']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = operations['getEngines']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, LovEnginesSearchResponse>

export function useLovEnginesSearchQuery(
  body: QueryBody = {},
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovEnginesSearch(body),
    async () => {
      const res = await apiFetcher<LovEnginesSearchResponse>(
        URLS.lovEnginesSearch,
        { method: HTTPMethod.POST, body }
      )

      return res.json
    },
    opts
  )
}

type EngineListArgs = {
  modelId: Maybe<string>
  fuelTypeId: Maybe<string>
  year?: Maybe<number>
}

export function useEngineList(args: EngineListArgs) {
  const { fuelTypeId, modelId, year } = args

  const { data, ...rest } = useLovEnginesSearchQuery(
    {
      filter: {
        ids: [],
        modelIds: modelId ? [modelId] : undefined,
        fuelTypeIds: fuelTypeId ? [fuelTypeId] : undefined,
        yearFromTo: year ?? undefined,
        yearFromIncludeNull: true,
        yearToFrom: year ?? undefined,
        yearToIncludeNull: true,
        active: true
      }
    },
    { enabled: !!modelId && !!fuelTypeId }
  )

  const engines = useMemo(() => {
    return (
      data?.content.map(engine => ({
        value: engine.id,
        label: [engine.name, engine.powerKw && `${engine.powerKw}kW`]
          .filter(Boolean)
          .join(' ')
      })) || []
    )
  }, [data])

  return { ...rest, engines }
}

export function useEngineDetail(engineId: Maybe<string>) {
  const { data, ...rest } = useLovEnginesSearchQuery(
    {
      filter: {
        ids: engineId ? [engineId] : [],
        yearFromIncludeNull: true,
        active: true
      }
    },
    { enabled: !!engineId }
  )

  const engine = useMemo(() => {
    return data?.content.find(engine => engine.id === engineId)
  }, [engineId, data])

  return { ...rest, data, engine }
}

export type LovEngine = LovEnginesSearchResponse['content'][number]
