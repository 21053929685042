import { components } from 'driverama-core/api/driverama/generated/auctions'
import { useTranslation } from 'react-i18next'

import IconCheckCircle from 'images/icons/IconCheckCircle.svg'
import IconCrossCircle from 'images/icons/IconCrossCircle.svg'
import IconMinusCircle from 'images/icons/IconMinusCircle.svg'

export function useOpportunityResult(
  result: components['schemas']['OpportunityResponse']['state']
) {
  const { t } = useTranslation(['core'])

  const label =
    result === 'DISCARDED'
      ? t('core:table_result_canceled')
      : result === 'LOST'
      ? t('core:table_result_lost')
      : result === 'DEAL' || result === 'APPROVED'
      ? t('core:table_result_won')
      : null

  const icon =
    result === 'DISCARDED' ? (
      <IconMinusCircle />
    ) : result === 'LOST' ? (
      <IconCrossCircle />
    ) : result === 'DEAL' || result === 'APPROVED' ? (
      <IconCheckCircle />
    ) : null

  return {
    label,
    icon
  }
}
