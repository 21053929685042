import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovFuelTypesResponse = operations['getFuelTypes']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getFuelTypes']['parameters']['query']

type QueryOpts = UseQueryOptions<unknown, unknown, LovFuelTypesResponse>

export function useLovFuelTypesQuery(params?: QueryParams, opts?: QueryOpts) {
  const searchParams = {
    ...params,
    sort: params?.sort?.[0]
  }

  return useQuery(
    QUERY_KEYS.lovFuelTypes(params),
    async () => {
      const res = await apiFetcher<LovFuelTypesResponse>(URLS.lovFuelTypes, {
        searchParams
      })

      return res.json
    },
    opts
  )
}

export function useFuelTypeList() {
  const { data, ...rest } = useLovFuelTypesQuery({ active: true })

  const fuelTypes = useMemo(() => {
    return (
      data?.content.map(fuelType => ({
        label: fuelType.name || fuelType.id,
        value: fuelType.id
      })) || []
    )
  }, [data])

  return { fuelTypes, ...rest }
}
