import { operations } from 'api/driverama/generated/lov'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export type LovMakesResponse = operations['getMakes']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getMakes']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovMakesResponse>

export function useLovMakesQuery(searchParams?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovMakes(searchParams),
    async () => {
      const res = await apiFetcher<LovMakesResponse>(URLS.lovMakes, {
        searchParams
      })

      return res.json
    },
    opts
  )
}

export function useMakeList(args?: {
  search?: string
  maxFeaturedCount?: number
}) {
  const { maxFeaturedCount, search } = args || {}
  const { data, ...rest } = useLovMakesQuery({ active: true })

  const carMakes = useMemo(() => {
    const makes = data?.content || []
    return makes
  }, [data])

  const featuredMakes = useMemo(
    () => carMakes.filter(make => make.featured).slice(0, maxFeaturedCount),
    [carMakes, maxFeaturedCount]
  )

  const filteredCarMakes = useMemo(() => {
    if (!search) {
      return carMakes
    }

    return carMakes.filter(make =>
      make.name?.toLowerCase().includes(search.toLowerCase())
    )
  }, [carMakes, search])

  return { ...rest, carMakes, featuredMakes, filteredCarMakes }
}

export function useMakeDetail(makeId: Maybe<string>) {
  const { data, ...rest } = useLovMakesQuery(
    { active: true },
    { enabled: !!makeId }
  )

  const make = useMemo(() => {
    return data?.content.find(make => make.id === makeId)
  }, [makeId, data])

  return { ...rest, data, make }
}

export type LovMake = LovMakesResponse['content'][number]
