import { media } from 'driverama-core/styles/media'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import { AuctionSearchBody } from '../../../api/driverama/auctions/searchAuctions'
import { FilterData } from '../../auctions/Auctions.utils'
import { LOSS_REASON_TYPE } from './lossReason/HistoryFiltersLossReason.utils'
import { SOURCE_TYPE } from './source/HistoryFiltersSource.utils'

export type HistoryStates = 'bought' | 'lost' | 'no_result'

export type HistoryOrder = 'newest' | 'oldest'

export function useHistoryFilterOpportunityStates(): {
  value: HistoryStates
  label: string
}[] {
  const { t } = useTranslation('history')

  return [
    { value: 'bought', label: t('filters_bought') },
    { value: 'lost', label: t('filters_lost') },
    { value: 'no_result', label: t('filters_no_result') }
  ]
}

export function useHistoryFiltersVariant(): 'mobile' | 'md' | 'desktop' {
  const isMobile = useMedia(media.lte('mobile'))
  const isMd = useMedia(media.lte('MD'))

  return isMobile ? 'mobile' : isMd ? 'md' : 'desktop'
}

export function mapFiltersStatesToOpportunityStates(
  filterStates?: FilterData['states']
): AuctionSearchBody['opportunityStates'] {
  return filterStates?.reduce(
    (acc: AuctionSearchBody['opportunityStates'], cur) => {
      if (cur === 'lost') {
        acc?.push('LOST')
      }

      if (cur === 'bought') {
        acc?.push('APPROVED', 'DEAL')
      }

      if (cur === 'no_result') {
        acc?.push('AGREED', 'PRICE_GUARANTEE', 'APP_IN_PROGRESS')
      }

      return acc
    },
    []
  )
}

export const useHistorySortOptions = (): {
  value: HistoryOrder
  label: string
}[] => {
  const { t } = useTranslation('history')

  return [
    {
      value: 'newest',
      label: t('filters_sort_newest')
    },
    {
      value: 'oldest',
      label: t('filters_sort_oldest')
    }
  ]
}

export function mapFiltersOrderToSort(filterOrder: HistoryOrder) {
  switch (filterOrder) {
    case 'newest':
      return 'createdAt,desc'

    case 'oldest':
      return 'createdAt,asc'

    default:
      return 'createdAt,desc'
  }
}

export interface SearchRange {
  min: number
  max: number
}

export interface HistoryFiltersData {
  page: number
  size: number
  order: HistoryOrder
  vin?: string
  licensePlate?: string
  winners?: string[]
  states: HistoryStates[]
  year?: SearchRange
  kilometers?: SearchRange
  modelIds?: string[]
  lossReasons?: LOSS_REASON_TYPE[]
  branches?: string[]
  carGeniuses?: string[]
  customerWishedPrice?: number
  source?: SOURCE_TYPE[]
}

export function sanitizeFilterValue<T>(value?: T[]) {
  if (!value || (Array.isArray(value) && !value.length)) {
    return undefined
  }

  return value
}
