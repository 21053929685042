import { LINKS } from 'constants/links'
import { getSession } from 'driverama-core/utils/auth'
import { createFetcher } from 'driverama-core/utils/fetcher'
import Router from 'next/router'

export const apiAuthFetcher = createFetcher(
  process.env.NEXT_PUBLIC_API_URL as string,
  async () => {
    try {
      const session = await getSession()

      return session
    } catch (e) {
      Router.push(LINKS.logout)

      return null
    }
  }
)
