import { URLS } from 'constants/api'

export const QUERY_KEYS = {
  lovCarTypes: URLS.lovCarTypes,
  lovBodies: (args?: Record<string, unknown>) => [URLS.lovBodies, args],
  lovColors: (args?: Record<string, unknown>) => [URLS.lovColors, args],
  lovMakes: (args?: Record<string, unknown>) => [URLS.lovMakes, args],
  lovBranchesSearch: (args?: Record<string, unknown>) => [
    URLS.lovBranchesSearch,
    args
  ],
  lovExtendedModelsSearch: (args?: Record<string, unknown>) => [
    URLS.lovExtendedModelsSearch,
    args
  ],
  lovModelsSearch: (args?: Record<string, unknown>) => [
    URLS.lovModelsSearch,
    args
  ],
  lovModelLinesSearch: (args?: Record<string, unknown>) => [
    URLS.lovModelLinesSearch,
    args
  ],
  lovEnginesSearch: (args?: Record<string, unknown>) => [
    URLS.lovEnginesSearch,
    args
  ],
  lovTransmissions: (args?: Record<string, unknown>) => [
    URLS.lovTransmissions,
    args
  ],
  lovFuelTypes: (args?: Record<string, unknown>) => [URLS.lovFuelTypes, args],
  lovDrives: (args?: Record<string, unknown>) => [URLS.lovDrives, args],
  lovCarFeaturesSearch: (args?: Record<string, unknown>) => [
    URLS.lovCarFeaturesSearch,
    args
  ],
  lovCarFeaturesSections: (args?: Record<string, unknown>) => [
    URLS.lovCarFeaturesSections,
    args
  ],
  lovCarFeaturesCategories: (args?: Record<string, unknown>) => [
    URLS.lovCarFeaturesCategories,
    args
  ],

  lovTechnicalSections: (args?: Record<string, unknown>) => [
    URLS.lovTechnicalSections,
    args
  ],
  lovTechnicalCategories: (
    args?: Record<string, unknown>,
    body?: Record<string, unknown>
  ) => [URLS.lovTechnicalCategories, args, body],
  lovTechnicalSubCategories: (
    args?: Record<string, unknown>,
    body?: Record<string, unknown>
  ) => [URLS.lovTechnicalSubCategories, args, body],
  lovTechnicalItems: (
    args?: Record<string, unknown>,
    body?: Record<string, unknown>
  ) => [URLS.lovTechnicalItems, args, body],
  lovCountries: (args?: Record<string, unknown>) => [URLS.lovCountries, args],
  lovStandardRepairs: (args?: Record<string, unknown>) => [
    URLS.lovStandardRepairs,
    args
  ],
  lovBonusesMaluses: (args?: Record<string, unknown>) => [
    URLS.lovBonusesMaluses,
    args
  ],

  auctionsSearch: URLS.auctionSearch,
  auctionsSearchAgg: URLS.auctionSearchAgg,
  auctionBidsSearch: (
    params?: Record<string, unknown>,
    args?: Record<string, unknown>
  ) => [URLS.searchAuctionsBid, params, args],
  auctionDiff: (auctionId?: string, args?: Record<string, unknown>) => [
    URLS.auctionDiff(auctionId),
    args
  ],

  carDetail: (id?: string) => URLS.carDetail(id),
  carMedia: (args?: Record<string, unknown>) => [URLS.carMedia, args],
  carTechnicalSections: (args?: Record<string, unknown>) => [
    URLS.carTechnicalSections,
    args
  ],
  carTechnicalCategories: (args?: Record<string, unknown>) => [
    URLS.carTechnicalCategories,
    args
  ],
  carTechnicalSubCategories: (args?: Record<string, unknown>) => [
    URLS.carTechnicalSubCategories,
    args
  ],
  carTechnicalSubCategoryPhotos: (args?: Record<string, unknown>) => [
    URLS.carTechnicalSubCategoryPhotos,
    args
  ],
  carTechnicalItems: (args?: Record<string, unknown>) => [
    URLS.carTechnicalItems,
    args
  ],
  carAccident: (id?: string) => URLS.carAccident(id),
  carWheels: (id?: string) => URLS.carWheels(id),
  carCosts: (id?: string) => URLS.carCosts(id),
  carFiles: (args?: Record<string, unknown>) => [
    URLS.carMedia,
    URLS.filesSearch,
    args
  ],
  carAdminBonusesMaluses: (carId?: string, args?: Record<string, unknown>) => [
    URLS.carAdminBonusesMaluses(carId),
    args
  ],
  carSearch: (args?: Record<string, unknown>) => [URLS.carSearch, args],
  auctionDetail: (id?: string) => URLS.auctionDetail(id),
  filesSearch: (args: Record<string, unknown>) => [URLS.filesSearch, args],
  partnersSearch: (args?: Record<string, unknown>) => [
    URLS.partnersSearch,
    args
  ],
  employeesSearch: (args?: Record<string, unknown>) => [
    URLS.employeesSearch,
    args
  ],
  noteSearch: (args?: Record<string, unknown>) => [
    URLS.opportunityNotesSearch,
    args
  ]
}
