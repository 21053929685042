import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/auctions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { useQuery, UseQueryOptions } from 'react-query'
import { FilterData } from 'sections/auctions/Auctions.utils'
import { apiAuthFetcher } from 'utils/authFetcher'
import {
  mapFiltersOrderToSort,
  mapFiltersStatesToOpportunityStates
} from '../../../sections/history/filters/HistoryFilters.utils'

export type AuctionSearchBody = paths['/auctions/search-agg']['post']['requestBody']['content']['application/json']

export type AuctionSearchResponseAgg =
  | paths['/auctions/search-agg']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

export type AuctionSearchResponse =
  | paths['/auctions/search-agg']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined
type QueryOpts = UseQueryOptions<unknown, unknown, AuctionSearchResponseAgg>

export type AuctionsSearchList = paths['/auctions/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']['content']

const filter15m: AuctionSearchBody = {
  ids: [],
  carIds: [],
  carMakeIds: [],
  states: ['IN_PROGRESS', 'READY_TO_START'],
  types: ['REGULAR', 'REPEATED'],
  opportunityStates: [
    'APP_IN_PROGRESS',
    'APP_FULLPRICING',
    'PRICE_GUARANTEE',
    'AGREED'
  ],
  excludedOpportunityStates: [],
  opportunityLossReasons: []
}

const filter48h: AuctionSearchBody = {
  ids: [],
  carIds: [],
  carMakeIds: [],
  states: ['IN_PROGRESS'],
  types: ['SEEN'],
  opportunityStates: [
    'APP_IN_PROGRESS',
    'APP_FULLPRICING',
    'PRICE_GUARANTEE',
    'AGREED'
  ],
  excludedOpportunityStates: [],
  opportunityLossReasons: []
}

const historyFilter = (filter?: FilterData): AuctionSearchBody => {
  return {
    ids: [],
    carIds: [],
    carMakeIds: [],
    carModelIds: filter?.modelIds,
    states: [
      'SUCCESSFUL',
      'IN_PROGRESS',
      'CANCELLED',
      'FAILED',
      'SUCCESSFUL_WINNER_CHANGED'
    ],
    opportunityStates: mapFiltersStatesToOpportunityStates(filter?.states),
    carVin: filter?.vin,
    winningPartnerErpIds: filter?.winners,
    carLicensePlate: filter?.licensePlate,
    carYearOfMakeFrom: filter?.year?.min,
    carYearOfMakeTo: filter?.year?.max,
    carSpeedometerMileageKmFrom: filter?.kilometers?.min,
    carSpeedometerMileageKmTo: filter?.kilometers?.max,
    types: [],
    excludedOpportunityStates: [],
    opportunityLossReasons:
      filter?.customerWishedPrice && !filter?.lossReasons
        ? ['SEEN_PRICE_DIFFERENCE']
        : filter?.lossReasons ?? [],
    opportunityBuyerIds: filter?.carGeniuses ?? [],
    opportunityBranchIds: filter?.branches ?? [],
    customerWishedPriceDifferenceTo: filter?.customerWishedPrice,
    opportunitySources: filter?.source
  }
}

export function useAuctionsSearch(filter?: FilterData, opts?: QueryOpts) {
  return useQuery(
    [QUERY_KEYS.auctionsSearchAgg, filter],
    async () => {
      const body =
        filter?.mode === '15m'
          ? filter15m
          : filter?.mode === '48h'
          ? filter48h
          : historyFilter(filter)

      const res = await apiAuthFetcher<AuctionSearchResponseAgg>(
        addSearchParams(URLS.auctionSearchAgg, {
          page: filter?.page && filter?.page - 1,
          size: filter?.size,
          sort: filter?.order ? mapFiltersOrderToSort(filter?.order) : undefined
        }),
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    opts
  )
}

export function useAuctionsSearchQuery(
  body: AuctionSearchBody,
  opts?: QueryOpts
) {
  return useQuery(
    [QUERY_KEYS.auctionsSearch, body],
    async () => {
      const res = await apiAuthFetcher<AuctionSearchResponse>(
        URLS.auctionSearch,
        { method: HTTPMethod.POST, body }
      )
      return res.json
    },
    opts
  )
}

export function useAuctionSearchByCarId(carId?: string, isInHistory?: boolean) {
  return useQuery(
    [QUERY_KEYS.auctionsSearch, isInHistory],
    async () => {
      const res = await apiAuthFetcher<AuctionSearchResponse>(
        URLS.auctionSearch,
        {
          method: HTTPMethod.POST,
          body: {
            carIds: carId ? [carId] : undefined
          }
        }
      )
      return res.json
    },
    {
      enabled:
        driverama.flags.IS_AUCTION_DIFF_ENABLED && !!carId && !isInHistory
    }
  )
}
