import { URLS } from 'driverama-core/constants/api'
import { Maybe } from 'driverama-core/utils/types'

export const QUERY_KEYS = {
  blogArticles: (args?: Record<string, unknown>) => ['blog-articles', args],
  blogCategories: (locale: string) => ['blog-categories', locale],
  blogFeaturedArticle: (locale: string | undefined) => [
    'blog-featured-article',
    locale
  ],
  blogFeaturedUpdates: 'blog-featured-updates',
  blogUpdates: (args?: Record<string, unknown>) => ['blog-updates', args],

  lovCarTypes: URLS.lovCarTypes,
  lovBodies: (args?: Record<string, unknown>) => [URLS.lovBodies, args],
  lovBodiesSearch: (args?: Record<string, unknown>) => [URLS.lovBodies, args],
  lovColors: (args?: Record<string, unknown>) => [URLS.lovColors, args],
  lovMakes: (args?: Record<string, unknown>) => [URLS.lovMakes, args],
  lovMakesSearch: (args?: Record<string, unknown>) => [
    URLS.lovModelsSearch,
    args
  ],
  lovBranchesSearch: (args?: Record<string, unknown>) => [
    URLS.lovBranchesSearch,
    args
  ],
  lovExtendedModelsSearch: (args?: Record<string, unknown>) => [
    URLS.lovExtendedModelsSearch,
    args
  ],
  lovModelsSearch: (args?: Record<string, unknown>) => [
    URLS.lovModelsSearch,
    args
  ],
  lovModelLinesSearch: (args?: Record<string, unknown>) => [
    URLS.lovModelLinesSearch,
    args
  ],
  lovEnginesSearch: (args?: Record<string, unknown>) => [
    URLS.lovEnginesSearch,
    args
  ],
  lovTransmissions: (args?: Record<string, unknown>) => [
    URLS.lovTransmissions,
    args
  ],
  lovPostCodes: (args?: Record<string, unknown>) => [URLS.lovPostCodes, args],
  lovFuelTypes: (args?: Record<string, unknown>) => [URLS.lovFuelTypes, args],
  lovDrives: (args?: Record<string, unknown>) => [URLS.lovDrives, args],
  lovEmissionNorms: (args?: Record<string, unknown>) => [
    URLS.lovEmissionNorms,
    args
  ],
  vinDecoderDecode: (vin?: string) => URLS.vinDecoderDecode(vin),
  opportunitiesSearch: URLS.opportunitiesSearch,
  opportunityDetail: (
    opportunityId?: string,
    args?: Record<string, unknown>
  ) => [URLS.opportunityDetail(opportunityId), args],
  freeSlotsBranch: (branchId?: string) => [URLS.freeSlotsBranch, branchId],
  carDetailFeatures: (featureIds?: string[] | null) => [
    'car-detail-features',
    ...(featureIds || [])
  ],
  lovCarFeaturesSearch: (args?: Record<string, unknown>) => [
    URLS.lovCarFeaturesSearch,
    args
  ],
  lovCarFeaturesSections: (args?: Record<string, unknown>) => [
    URLS.lovCarFeaturesSections,
    args
  ],
  lovCarFeaturesCategories: (args?: Record<string, unknown>) => [
    URLS.lovCarFeaturesCategories,
    args
  ],
  lovCountries: (args?: Record<string, unknown>) => [URLS.lovCountries, args],
  customerSelf: [URLS.customerSelf],
  employeesSearch: (args?: Record<string, unknown>) => [
    URLS.employeesSearch,
    args
  ],
  employeeSelf: [URLS.employeeSelf],
  userReviews: 'user-reviews',
  faqQuestions: (category?: string) => ['faq-questions', category],
  faqQuestion: (questionId?: string) => ['faq-question', questionId],
  faqSearch: (search?: string) => ['faq-search', search],
  featureFlag: (key?: string) => ['firebase', 'remote', 'config', key],
  checkEmail: (email?: string) => ['check', 'email', email],
  checkPhone: (phone?: string) => ['check', 'phone', phone],
  careersPositions: (locale?: string) => ['positions', locale],

  branch: (branchId?: string, locale?: string) => ['branch', branchId, locale],
  branches: (locale?: string) => ['contentful', 'branches', locale],

  appointmentsFreeSlotsBranch: (branchId: string, type: string) =>
    URLS.appointmentsFreeSlotsBranch(branchId, type),
  appointmentsFreeSlotsMobile: (branchId: string, type: string) =>
    URLS.appointmentsFreeSlotsMobile(branchId, type),

  carDetail: (stockNo?: string) => URLS.carDetail(stockNo),
  stockCarDetail: (carId?: string) => URLS.stockCarDetail(carId),
  stockCarDetailAlt: (stockNo?: string) => URLS.stockCarDetailAlt(stockNo),
  orderDetail: (orderId?: string) => URLS.orderDetail(orderId),
  orderNotes: (orderId?: string) => URLS.orderNotes(orderId),
  orderLoanCalculator: () => URLS.orderLoanCalculator(),
  orderDeliveryPrices: () => URLS.orderDeliveryPrices(),
  orders: (args?: Record<string, unknown>) => ['orders', args],
  orderUpsales: (args?: Record<string, unknown>) => ['orders', 'upsales', args],
  orderHomeDeliveryInfo: 'order-home-delivery-info',
  orderHomeDeliveryDistance: (lat: number, lng: number) => [
    'orders',
    'home-delivery',
    'distance',
    { lat, lng }
  ],
  orderHomeDeliveryAvailability: (args: { lat?: number; lng?: number }) => [
    URLS.orderHomeDeliveryAvailability,
    args
  ],
  extendedWarranties: (carId: Maybe<string>) => URLS.extendedWarranties(carId),
  fuelTypeLabels: URLS.fuelTypeLabels,
  linearLoanCalculation: URLS.linearLoanCalculation,
  balloonLoanCalculation: URLS.balloonLoanCalculation
}
